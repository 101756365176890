<template>
  <div class="inspection-history">
    <div class="top">
      <WeekdayCalendar :init-date="getWeekdayDate" @change="changeDate" @showInfo="showInfo"/>
      <img class="icon" :src="calendarIcon" @click.stop="showPopup=true"/>
    </div>
    <van-popup v-model="showPopup" position="top" closeable :style="{ height: '70%' }">
      <CalendarVue v-if="showPopup" maxHeight="100%" :init-date="planArriveDate" @change="(val)=>(changeDate(val,true))"/>
    </van-popup>
    <div v-if="inspectionInfo" class="content">
      <div class="content-block">
        <div class="info">
          <div class="item blod mg-8">
            <div class="item-l">{{ detail.driveCar.vinCode }}</div>
            <div class="item-r">{{ detail.driveCar.carNumber }}</div>
          </div>
          <div class="item mg-8"><span>{{ $t('车系') }}：</span>{{ detail.driveCar?.seriesName }}</div>
          <div class="item mg-8"><span>{{ $t('车型') }}：</span>{{ detail.driveCar?.modelName }}</div>
          <div class="item mg-8"><span>{{ $t('外饰颜色') }}：</span>{{ detail.driveCar?.exteriorColor }}</div>
          <div class="item mg-8"><span>{{ $t('内饰颜色') }}：</span>{{ detail.driveCar?.interiorColor }}</div>
          <!-- <div class="item mg-8"><span>{{ $t('点检结果') }}：</span>{{ dataSource.driveCar?.inspectResult | codeFormat }}</div> -->
        </div>
        <div v-for="(item, index) in inspectionInfo.taskList" :key="index" class="info">
          <div class="info_l">
            <p class="inspectType mg-8">{{ item.inspectType | codeFormat }}</p>
            <p class="userInfo mg-8">
              <span class="time">{{ item.updateTime }}</span>
              <span v-if="item.updateBy" class="name">{{ item.updateBy }}</span>
            </p>
            <p class="status">
              <span :class="['circle', item.inspectResult == 2027002 ? 'pass' : 'fail']"></span>
              <span class="name">{{ item.inspectResult | codeFormat }}</span>
              <span class="flex-box">
                <span>{{ $t('是否逾期') }}：</span>
                <span :class="[item.overDue ? 'fail' : 'pass']">{{ item.overDue ? '是' : '否' }}</span>
              </span>
            </p>
          </div>
          <div v-if="item.inspectResult === '2027002'" class="info_r" @click="goDetail(item)">
            {{ $t('查看详情>') }}
          </div>
        </div>
      </div>
    </div>
    <Empty v-else :description="$t('暂无数据')"></Empty>
  </div>
</template>
<script>
import loading from '@/utils/loading'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import calendarIcon from '@/images/calendar.svg'
import inspectionServices from '@/services/inspectionServices'
import WeekdayCalendar from './components/WeekdayCalendar.vue'
import CalendarVue from './components/Calendar.vue'
export default {
  components: {
    WeekdayCalendar,
    CalendarVue
  },
  data() {
    return {
      calendarIcon,
      initDate: new Date(),
      planArriveDate: dayjs(),
      showPopup: false,
      detail: {
        driveCar: {}
      },
      inspectionInfo: {
        flag: '',
        taskList: []
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    getWeekdayDate() {
      const { planArriveDate } = this
      return planArriveDate ? new Date(planArriveDate) : ''
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    // 获取点检详情
    async getDetail() {
      loading.showLoading()
      const res = await inspectionServices.inspectDetail({ id: this.$route.query.taskId })
      loading.hideLoading()
      this.detail = Object.assign(this.detail, res)
    },
    changeDate(val) {
      this.planArriveDate = val
      this.showPopup = false
    },
    showInfo(value) {
      const date = dayjs(new Date(this.getWeekdayDate)).format('YYYY-MM-DD')
      this.inspectionInfo = this.$options.data().inspectionInfo
      this.inspectionInfo = value[date] ? Object.assign(this.inspectionInfo, value[date]) : ''
    },
    // 跳转详情
    goDetail({ id }) {
      this.$router.push({
        path: '/inspection-operate',
        query: {
          taskId: id,
          operate: 'view'
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.inspection-history{
  font-size: 13px;
  .top{
    background: #fff;
    display: flex;
    align-items: center;
    padding-right: 8px;
    /deep/ .dateList{
      flex: 1;
    }
    .icon{
      width: 24px;
      height: 24px;
      margin-left: 8px
    }
  }
  .content{
    padding: 16px;
    .content-block{
      display: flex;
      flex-direction: column;
      background: #fff;
      padding: 16px 12px 0 12px;
      border-radius: 8px;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
      .mg-8{
        margin-bottom: 8px
      }
      .info{
        display: flex;
        flex-wrap: wrap;
        &:not(:first-of-type) {
          border-top: 1px solid rgba(13, 23, 26, 0.05);
          padding: 16px 0;
        }
        .item{
          color: @black;
          width: 50%;
          &.blod{
            font-size: 16px;
            font-weight: 500;
            display: flex;
            width: 100%;
            justify-content: space-between;
          }
        }
        .info_l{
          flex: 1;
          .inspectType{
            font-size: 16px;
            font-weight: 500;
            color: @yellow-text;
          }
          .userInfo{
            .name{
              padding-left: 16px;
              position: relative;
              &::before{
                content: '';
                position: absolute;
                top: 50%;
                left: 8px;
                width: 1px;
                height: 70%;
                background-color: #D9D9D9;
                transform: translateY(-50%);
              }
            }
          }
          .status{
            display: flex;
            align-items: center;
            .flex-box {
              width: 50%;
              text-align: right;
            }
            .circle{
              width: 12px;
              height: 12px;
              border-radius: 12px;
              margin-right: 10px;
              &.pass{
                background: green;
              }
              &.fail{
                background: red;
              }
            }
            .pass {
              color: green;
            }
            .fail {
              color: red;
            }
          }
        }
        .info_r{
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: @yellow-text;
        }
      }
    }
  }
}
</style>